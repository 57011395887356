/* Genome font icon styles */
@font-face {
  font-family: 'genome';
  src:    url('#{$font-path}genome.eot?3f9exi');
  src:    url('#{$font-path}genome.eot?3f9exi#iefix') format('embedded-opentype'),
  url('#{$font-path}genome.ttf?3f9exi') format('truetype'),
  url('#{$font-path}genome.woff?3f9exi') format('woff'),
  url('#{$font-path}genome.svg?3f9exi#genome') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ge-"], [class*=" ge-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'genome' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ge-logo:before {
  content: "\e900";
}

h6, blockquote, h4 {
  word-wrap: break-word;
}

.text-white {
  color: #fff;
}

.text-muted {
  color: $gray;
}

.text-bold {
  font-weight: 600;
}

a {
  &, &:visited {
    color: $primary-500;
    text-decoration: none;
  }
  &:active, &:focus, &:hover {
    text-decoration: underline;
    color: $primary-700;
  }
}

.capitalize {
  text-transform: capitalize;
}
.lowercase {
  text-transform: lowercase;
}