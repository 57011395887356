.creative-work-container {
  width: 100%;
  height: 100%;

  .back-link {
    .md-button {
      font-size: 11px;
      margin: 10px 0 -10px 0;
      padding:0 4px 0 0;
      line-height: 18px;
      min-height: 18px;
      .material-icons {
        font-size: 14px;
        position: relative;
        top: -1px;
      }
    }
  }

  .video-player .video-js:not(.vjs-fullscreen) {
    width: 450px;
    height: 255px;
    margin-right: 10px;
    @media #{$screen-upto-desktop-sm} {
      width: 100%;
    }
  }

  .card-thumb {
    margin: 0;
    height: 310px;
    position: relative;
    overflow: hidden;
    background: $gray-light !important;
    text-align: center;

    @media #{$screen-upto-tablet} {
      width: 100%;
      min-width: 100%;
      display: block;
      flex: none;
      margin: 0;
    }
    
    @media #{$screen-tablet-lg} {
      order: 1; 
      width: 50%;
      display: flex;
      flex: 1;
      min-width: 0;
      margin-right: 10px;
    }

    > div {
      height: 100%;
      width: 100%;
      display: flex;
      @media #{$screen-upto-desktop-lg} {
        padding: 10px;
      }
    }

    .thumb {
      display: block;
      margin: auto;
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
    }

    .play-overlay {
        opacity: .5;
        color: #fff;
        position: absolute;
        top: 50%;
        margin-top: -25px;
        left: 50%;
        margin-left: -25px;
        font-size: 50px;
        background-color: #000;
        border-radius: 100px;
      }

  }

  .card-data {
    background: $gray-light !important;
    overflow: auto;
    height: 310px;
    margin: 0;
  
    h4 {
      padding: 5px 8px;
      margin: 0;
      background: $gray;
    }
  
    p {
      padding: 0 15px;
    }
  
  }
  
  .synopsis-data {
    display: flex;
    flex: 1;
    min-width: 0;
    margin: 0 20px;
    
    @media #{$screen-upto-tablet} {
      margin: 20px 0 !important;
      width: 100%;
      display: block;
      flex: none;
      min-width: 100%;
      margin: 0;
      height: auto;
    }

    @media #{$screen-tablet-lg} {
      order: 3; 
      width: 100%;
      display: block;
      flex: none;
      min-width: 100%;
      margin: 20px 0 0 0;
      height: auto;
    }

  }

  .snapshot-data {
    width: 400px;
    border-collapse: collapse;

    @media #{$screen-upto-tablet} {
      width: 100%;
      display: block;
      flex: none;
      min-width: 100%;
      margin: 0;
      height: auto;
    }

    @media #{$screen-tablet-lg} {
      order: 2; 
      width: 50%;
      display: flex;
      flex: 1;
      min-width: 0;
      margin-left: 10px;
    }
  
    dl.list-horizontal {
      display: table;
      font-size: 12px;
      border-bottom: solid 1px $table-border-color;
      margin: 0 3px;
      padding: 0;
  
      &:last-child {
        border-bottom: 0;
      }
  
      @media #{$screen-upto-desktop-lg} {
        width: 99%;
      }
  
      dt, dd {
        display: table-cell;
        margin: 0;
        line-height: 18px;
        padding: 5px 3px;
        span {
          display: inline-block;
          @include ellipsis();
          white-space:normal;
          overflow: visible;
        }
      }
      dt {
        font-weight: bold;
        span {
          width: 135px;
        }
      }
      dd {
        span {
          width: 240px;
        }
      }
  
    }
  
  }

  .md-subheader {

    .md-subheader-inner {
      padding-top: 0;
    }

    .md-headline {
      margin: 10px 0 0 0;
    }

    .md-subhead {
      margin: 0;
      font-weight: bold;
    }

  }

  .content {
    padding: 0 16px;
    z-index: 1;
    position: relative;
  }
  .description-container {
    padding: 10px 5px;
  }
  md-list-item {
    padding: 5px 10px !important;
    height: auto;
    min-height: 0;
    font-size: 12px;
  }
  .md-list-item-text strong {
    margin-right: 10px;
  }
  .details-content .md-list-item-text {
    width: 100%;
  }
}

.details-content {
  overflow: hidden;
  width: auto;
  white-space: nowrap;
}

.details-viewer {
  .detailsPull {
    transition: margin 1s;
  }
  md-list {
    padding: 0;
    margin: 0;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    md-list-item:last-child md-divider {
      display: none;
    }
  }
}

.details-nav {
  margin-left: 15px;
  i {
    font-size: 8px;
    opacity: .54;
    transition: opacity .5s;
  }
  .md-button {
    &:hover i, &.active i {
      opacity: 1;
    }
    &.md-icon-button {
      height: 16px;
      width: 16px;
      line-height: 6px;
      padding: 2px;
      min-height: 0;
      margin: 0;
    }
  }
}

.nested-data {
  display: block;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  border-left: 5px solid #ccc;
  background: #fff;

  > li > .nested-data {
    display: none;
  }

  > li.expanded {

    > .nested-data {
      display: block;
    }

    md-icon {
      transform: rotate(90deg);
    }

  }

  &, & > li {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
  }

  > li > div {
    position: relative;
    overflow: hidden;
    font-size: 13px;
    padding: 0 5px;
    line-height: 32px;
    border-bottom: solid 1px $gray;

    &.expandable {
      outline: none;
      cursor: pointer;
      &:hover {
        background: $gray-light;
      }
    }

    .label {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      margin: 0;
      text-align: right;
      line-height: 32px;
    }

  }


}

.template-container {
  margin: 20px 0;
}

.template-nav {
  margin: 20px 0;
  position: relative;
  border-right: 0;
  border-bottom: 0;
  z-index: 1;
  .template-nav-card {
    border-radius: 3px 0 0 3px;
    overflow: hidden;
  }
  .nav-indicator-bar {
    width: 3px;
    height: 65px;
    position: absolute;
    top: 0;
    right: 0;
    transition: top .5s;
    background: rgb(255, 87, 34);
  }
  .md-button.md-icon-button {
    background: #ffffff;
    padding: 5px;
    border-radius: 0;
    margin: 0;
    width: auto;
    height: auto;
    box-sizing: border-box;
    i {
      font-size: 48px;
    }
  }
  .md-icon-button.active {
    background: #ffffff;
  }
}

.template-group-container {
  margin-left: 0 !important;
  z-index: 2;
}

.template-group {
  display: none;
  padding: 10px;
  &.active {
    display: block;
  }
}

.collapse-container {
  overflow: hidden;
  border-radius: 0px;
  margin: 10px 0;
  background: #fff;
  h4 {
    margin: 0;
    padding: 5px 8px;
    overflow: hidden;
    position: relative;
    border-radius: 0px;
    background: $primary-50;
    border-left: solid 4px $primary-200;
    color: $gray-darker;
    line-height: 25px;
    &:hover {
      color: #000;
      border-color: $primary-400;
    }
    i {
      vertical-align: middle;
      float: right;
    }
  }
  .content {
    height: 0;
    overflow: hidden;
    border: solid 2px $primary-50;
    padding: 0;
    //border-top: 0;

  }
  &.expanded {

    h4 {
      color: #000;
      border-color: $primary-400;
      i {
        transform: rotate(90deg);
      }
    }
    .content {
      height: auto;
      padding: 10px;
    }
  }
}

.field-row {
  background: #fff;
}

.field {
  background: #ffffff;
  padding: 0 10px;

  &:first-child {
    border-radius: 4px 4px 0 0;
  }
  &:last-child {
    border-radius: 0 0 4px 4px;
  }
  overflow: hidden;
  > div {
    overflow: hidden;
  }
}

.fieldLabel {
  width: 200px;
  padding-right: 16px;
  box-sizing: border-box;
  font-weight: bold;
  float: left;
  margin-top: 12px;
}

.fieldContainer {
  margin-left: 210px;
}

.chip-field .md-chip-content {
  font-size: 12px !important;
}

.field:last-child md-divider {
  //display: none !important;
}

.text-area-field {
  margin: 14px 0;
}

.list-field {

  .field > span,
  .field > a {
    font-size: 13px;
    line-height: 16px;
    padding: 3px 3px 0 3px;
    max-width: 300px;
    min-height: 16px;
    display:inline-block;
    @include ellipsis();
    @media #{$screen-upto-desktop-xl} {
      max-width: 265px;
    }
    @media #{$screen-upto-desktop-lg} {
      max-width: 180px;
    }
    @media #{$screen-upto-desktop-sm} {
      max-width: 80%;
    }
  }

  &.vertical-divide {
    @media #{$screen-desktop-sm} {
      margin-bottom: 7px;
      .field {
        border-radius: 0;
        border-right: solid 1px $gray;
        width: 75%;
        md-divider {
          display: none;
        }
      }
      > div:last-child .field {
        border-right: none;
      }
    }
    .field > span,
    .field > a {
      width: 80%;
    }
  }

}



.creative-work-tabs {
  margin: 20px 0;
  padding: 10px;
  md-tab, md-content {
    background: #fff;
  }
}

.pagination {
  margin-bottom: $line-height-base;
  padding: 0 7px;
  .md-subhead {
    font-weight:bold;
  }
  button {
    min-width: 36px;
    margin-left: 3px;
    margin-right: 3px;
  }
}

.related-content {
  min-height: 100px;
}

//POC *****
.creative-work-dialog {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  max-width: 100%;
  max-height: 100%;


  md-dialog-content {
    position: relative;
    height: 100%;
    overflow-x: hidden;

    .video-metadata-container,
    .thumbnail-container,
    .metadata-container,
    .video-container,
    .virtual-repeat-wrapper,
    .vertical-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .video-metadata-container {
      right: 250px;
      overflow: hidden;
    }

    .thumbnail-container {
      left: auto;
      width: 250px;
      background-color: $bg-100;
      box-shadow: inset 7px 0 9px -7px rgba(0,0,0,0.7);

      .repeated-item {
        box-sizing: border-box;
        height: 180px;
      }

      md-card {
        &:hover {
          background-color: $primary-500 !important;
          color: #fff !important;
        }
      }

    }

    .metadata-container {
      top: 50%;
      overflow: auto;

      md-list-item {
        padding-bottom: 16px;
      }

      md-tab-content {
        md-content {
          background-color: transparent;
        }
      }

      > .md-button {
        position: absolute;
        right: 5px;
        top: 0;
      }

    }

    .video-container {
      bottom: 50%;
    }

    .video-js {
      width: 100%;
      height: 100%;
    }

  }


}

//Select Metadata

.select-metadata-list {

  .select-metadata-label {
    width: 200px;

    .md-avatar-icon {
      background-color: rgba(0,0,0,0.26);
      color: rgb(236,239,241);
    }

  }

  .select-metadata-items {
    padding: 10px 0;
  }

}

.select-metadata-buttons {
  width:125px;
  text-align: right;
}
