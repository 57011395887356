md-tooltip {
  z-index: $md-tooltip-zindex !important;

  &.md-default-theme .md-content, & .md-content {
    background: rgb(0, 0, 0);
  }

  @media #{$screen-upto-tablet} {
      display: none;
  }

}
