
.alert {
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid transparent;
  font-size: .85em;

  h3, p {
    margin: 0 0 10px 0;
  }

  &.ng-enter {
    transition:0.5s linear all;
    opacity:0;
  }

  &.ng-enter.ng-enter-active {
    opacity:1;
  }

}

.alert-warning {
  background-color: $warning-100;
  border-color: $warning-900;
  color: $warning-900;

  md-icon {
    color: $warning-900;
  }
}

.alert-danger {
  background-color: $danger-100;
  border-color: $danger-900;
  color: $danger-900;

  md-icon {
    color: $danger-900;
  }
}

md-toast {
  z-index: $md-toast-zindex;
  .md-toast-content {
    height: auto;
    min-height: inherit;
    max-height: inherit;
    padding: 10px;

    .md-button {
      min-width: 45px;
    }

    .material-icons {
      min-width: 24px;
    }

  }
}