//Opacity
@mixin opacity($percent) {
  opacity: $percent/100;
  filter: alpha(opacity = $percent);
}

//Border Radius
@mixin border-bottom-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  -moz-border-radius-bottomright: $radius;
  border-bottom-right-radius: $radius;
}

//Box Shadow
@mixin no-shadow() {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
