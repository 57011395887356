md-chips .md-chips {
  &, &.md-focused {
    @include no-shadow();
  }
}

.chip-icon {
  position: relative;
  top: -1px;
  color: white;
  fill: white;
  height: 14px;
  width: 14px;
  text-align: center;
  border-radius: 50%;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  margin-top: 2px;

  &:hover {
    background: $danger-500;
    cursor: pointer;
  }
}


.static-chip{
  background: grey;
  color: white;
  padding: 2px 5px;
  border-radius: 1em;
  font-size: .8em;
  font-weight: bold;
  margin: 0 3px;
  line-height: 1.5em;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

}