md-dialog {
  z-index: $md-dialog-zindex;

  &.panel-dialog {
    opacity: 1;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  &.metadata-panel {
    max-height: 75%;
    width: 900px;
    max-width: 90%;

    .md-body-1 {
      padding-bottom: 10px;
    }
  }

  md-dialog-content {
    background-color: rgb(255, 255, 255) !important;

    .login-dialog {
      @media (min-width: $breakpoint-mobile) {
        width: 300px;
      }

      .myid-logo {
        height: 22px;
        margin: 15px 0 0 5px;
      }

    }

  }

}


