.video-dialog {

  .video-js .vjs-poster {
    background-size: 100% 100%;
  }

  &.collapsed {
    min-width:700px !important;

    &, md-dialog-content {
      overflow: visible !important;
    }

    .video-js:not(.vjs-fullscreen) {
      width: 725px !important;
      height: 408px !important;
    }

    .md-toolbar-tools h2 {
      @include ellipsis();
      max-width: 600px;
    }

    .segment-container, .video-title {
      display: none;
    }

  }

  &.expanded {
    position: absolute;
    top: 5%;
    right: 2.5%;
    bottom: 5%;
    left: 2.5%;
    max-width: 100%;
    max-height: 100%;

      md-dialog-content {
        position: relative;
        height: 100%;
        overflow-x: hidden;

        .video-metadata-container,
        .thumbnail-container,
        .metadata-container,
        .video-container,
        .virtual-repeat-wrapper,
        .vertical-container {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }

        .thumbnail-container {
          left: auto;
          width: 250px;
          background-color: $bg-100;
          box-shadow: inset 7px 0 9px -7px rgba(0,0,0,0.7);

          .repeated-item {
            box-sizing: border-box;
            height: 180px;
          }

          md-card {
            &:hover {
              background-color: $primary-500 !important;
              color: #fff !important;
            }
          }

        }

        .metadata-container {
          top: 50%;
          overflow: auto;

          md-list-item {
            padding-bottom: 16px;
          }
          
          md-tab-item.md-disabled > span {
            // NOTE: in case Find scenes tab should be hidden instead of just disabled
            visibility: hidden;
          }

          md-tab-content {
            md-content {
              background-color: transparent;
            }
          }

          > .md-button {
            position: absolute;
            right: 5px;
            top: 0;
          }

        }

        .video-container {
          bottom: 50%;
        }

        .video-js {
          width: 100%;
          height: 100%;

          .video-title {
            display: inline-block;
          }
          
          // NOTE: save in case re-implement video dialog toolbar
          /*
          &:not(.vjs-fullscreen) {
            .close-video {
              display: none;
            }
          }
          */

        }

      }

    .loop-segment-btn,
    .vjs-time-control.segment-time,
    .restart-loop,
    .loop-bar {
      display: none;
    }

  }

  &.show-thumb-nav {

    .video-metadata-container {
      right: 250px !important;
      overflow: hidden;
    }

  }

  // &.search-result {
  //   .vjs-fullscreen .vjs-control-bar .metadata-view-spacer {
  //     flex-grow: .75;
  //   }     
  // }

}

//Select Metadata

.select-metadata-list {

  .select-metadata-label {
    width: 200px;

    .md-avatar-icon {
      background-color: rgba(0,0,0,0.26);
      color: rgb(236,239,241);
    }

  }

  .select-metadata-items {
    padding: 10px 0;
  }

}

.select-metadata-buttons {
  width:125px;
  text-align: right;
}
