.gen-search-menu-wrapper {
  width: 0 !important;
  height: 0 !important;
}

.gen-search-menu {
  background: white;
  border-radius: 4px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
  0 13px 19px 2px rgba(0, 0, 0, 0.14),
  0 5px 24px 4px rgba(0, 0, 0, 0.12);
  overflow-y: auto;

  md-list {
    padding: 0;
  }

  .md-subheader .md-subheader-inner {
    padding: 8px 15px;
  }

  md-divider {
    margin: 0 0 10px 0;
  }

}

.gen-search-menu-item {
  cursor: pointer;
  min-height: 0;

  > button:hover {
      background-color: $primary-100 !important;
  }

  &.selected > button, > button:focus {
    background-color: $primary-500 !important;
    color: #fff;
  }

  .md-list-item-inner {
    min-height: 0;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .highlighted {
    font-weight: bold;
  }

}
