.card-set-container {
  clear:both;
  padding-bottom: 32px;
}

.no-results {
  color: darken($gray, 10%);
  padding: 12px 7px;
  font-size: 22px;
  font-weight: bold;
}

.open-facets-btn {
  margin-left: 10px;
}

.search-results-count-container {
  .facets-option-name {
    padding: 4px 5px 4px 10px;
    * {
      outline: none;
    }
  }
}

.search-chips-container {
  > div {
    margin-left: 2px;
  }
}

.search-text-container {
  font-size: 11px;
  margin: 0px 0px 3px 8px;
  padding-top: 7px;
}

.search-text-label {
  display:inline-block;
  max-width: 80%;
}

.card-set-buttons {
  margin-right: 10px;

  md-input-container {
    margin: 0px;
  }

}

.sort-option-menu {
  padding: 0;
}

.sort-option-menu-content {
  .md-button.selected {
    color: $primary-500;
  }
}