.landing-container {
  height: 50%;
  min-height: 200px;

  .search-container {
    width: 50%;
    max-width: 550px;

    @media #{$screen-upto-tablet} {
      width: 95%;
    }

    .search-wrapper {
      border: solid 1px $gray;
      padding: 8px;
      background: rgba(0,0,0,0.12);
      border-radius: 4px;
      box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
    }

  }

}

.landing-logo {

  margin-bottom: 20px;

  .ge-logo {
    font-size: 65px;
    margin-right: 5px;
    @media #{$screen-upto-tablet} {
      font-size: 40px;
    }
  }

  .title {
    font-size: 36px;
    @media #{$screen-upto-tablet} {
      font-size: 20px;
    }
  }

  .sub-title {
    font-size: 20px;
    @media #{$screen-upto-tablet} {
      font-size: 12px;
    }
  }

}