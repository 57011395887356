.form-control{
  box-sizing: border-box;
  width: 100%;
  border: solid 1px $gray;
  border-radius: 3px;
  padding: 2px 4px;
  outline: none;
  position: relative;
  height: 32px;
  transition: border-color ease-in-out .15s;

  &::-ms-clear {
    display: none;
  }

  &[placeholder] {
    font-size: 14px;
  }
  &:focus {
    outline: none;
    border-color: $primary-400;
  }

  &.input-sm {
    border-radius: 2px;
    padding: 1px 4px;
    font-size: 12px;
    height: 22px;
    &[placeholder] {
      font-size: 12px;
    }
  }

  &.input-lg {
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 16px;
    height: 36px;
    &[placeholder] {
      font-size: 14px;
    }
  }
}

.input-group {
  display: flex;
  flex: 1;

  .form-control {
    flex: 1;
  }

  .input-group-addon {
    width:auto;
    margin: 0;
    min-width: 36px;
    line-height: 32px;
    min-height: 32px;
    border: solid 1px $gray;
    border-left: 0;
    box-shadow: none !important;
  }

  .form-control:first-child, .input-group-addon:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .form-control:last-child, .input-group-addon:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

}

.clear-search-group {
  position: relative;
  flex: 1;

  .form-control {
    padding-right: 24px;
  }

  &:hover .clear-search-parameters {
    visibility: visible;
  }

  .clear-search-parameters {
    background: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    visibility: hidden;
    margin: 0;
    outline: none;
    width: 32px;
    cursor: pointer;
    &:focus md-icon, md-icon:focus {
      color: #333 !important;
    }
  }

}