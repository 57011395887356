.logged-in-text {
  font-size: 10px;
  margin-bottom: 5px;
  padding-right: 6px;

  .logout-link {
    color: $primary-50;
    text-decoration: none;

    &, md-icon {
      font-size: inherit;
      transition: color ease-in-out 200ms;
    }

    &:hover, &:focus, &:active {
      &, md-icon {
        color: #fff;
      }
    }

  }

}

.progress-indicator {
  position: fixed;
  top: 0;
  width: 100%;
}


.toolbar-actions {

  .action-title {
    font-size: 10px;
  }

  div:last-child .md-button {
    margin-right: 0 !important;
  }

  .md-button {
    @media #{$screen-upto-desktop} {
      min-width: 0;
    }
  }

}

.toolbar-logo .ge-logo {
  font-size: 40px;
  margin-right: 10px;
}

.toolbar-logo .title {
    font-size: 22px;
    font-weight: bold;
    margin-right: 10px;
}

.genome-toolbar {

  .sub-title {
    font-size: 11px;
  }

  md-progress-linear.md-accent .md-container {
    background-color: rgba(250, 250, 250, 0.5);
  }
}

.clear-search-icon {
  color: $gray !important;
}
