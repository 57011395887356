
.facets {
  padding-top: 0;
}

body md-checkbox.md-checked .md-icon {
  &:after {
    left: 5.67px;
    top: 1.22px;
  }
  border: 1px solid rgba(0, 0, 0, 0.54);
}

.facet-options-container {
  @media (min-width:960px) {
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 10px;
  }
}

.facet-option-container {
  padding-left: 10px;
}

.facet-option {
  display: inline-block;
  white-space: nowrap;
  width: 210px;
}

.facet-name {
  padding: 5px;
  margin-top: 10px;
  max-width: 150px;
  font-size: 12px;
  text-align: left;
}

.facet-search-input {
  margin: 10px;
  max-width: 86%;
}

.ended-with-dot-component {
  cursor: default;
  max-width: 170px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 5px 0 0;
}

.facets-option-name {
  word-wrap: break-word;
  white-space: normal;
  max-width: 200px;
  font-size: 10px;
  padding: 0px 5px 0px 10px;
  margin: 2px;
  line-height: 20px;
  border-radius: 1em;
  background: grey;
  color: white;

  &.clear-search, &.share-search {
    white-space: nowrap;
    position: relative;
    font-size: 12px;
    background: $danger-500;

    .chip-icon:hover {
      background: $danger-900;
    }

  }

  &.clear-search {
    left: 10px;
  }

  &.share-search {
    background: $primary-500;
    top: -3px; 

    .chip-icon {
      font-size: 16px;
      top: -2px;
      transform: scaleX(-1);
      &:hover {
        background: $primary-900;
      }
    }

  }

}

.option-name {
  vertical-align: middle;
  display: inline-block;
}

.option-name {
  max-width: 90%;
}

.option-count {
  color: $gray-dark;
}

.facet-header {
  width: 100%;
  cursor: pointer;
  outline: none;
}

.odd {
  background-color: #f1f1f1;
}

.facets-container {
  width: 100%;
  font-size: 12px;
}

.custom-button {
  position: relative;
  top: -1px;
  color: black;
  fill: black;
  height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 25%;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 12px;
  outline: 0;
  &:hover {
    background: orange;
    cursor: pointer;
  }
}

.facet-no-results {
  display: block;
  color: $gray-dark;
  padding: 0 5px 10px 5px;
  font-size: 11px;
}

.facet-checkbox { 
  overflow: hidden;
  
  md-checkbox {
    min-width: $facet-checkbox-size;
    min-height: $facet-checkbox-size;
    margin-bottom: $facet-checkbox-size/2;

    .md-container {
      width: $facet-checkbox-size;
      height: $facet-checkbox-size;

      &:after {
        top: (-$facet-checkbox-size/2);
        right: (-$facet-checkbox-size/2);
        bottom: (-$facet-checkbox-size/2);
        left: (-$facet-checkbox-size/2);
      }
    }

    .md-icon {
      width: $facet-checkbox-size;
      height: $facet-checkbox-size;
    }

    &.md-checked .md-icon:after {
      left: $facet-checkbox-icon-size/3 !important;
      top: 0 !important;
      width: $facet-checkbox-icon-size/2 !important;
      height: $facet-checkbox-icon-size !important;
    }
  }

}