.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.right {
  float: right;
}

.left {
  float: left;
}

.clear-both {
  clear: both;
}

.ellipsis {
  @include ellipsis();
}

.inline-block {
  display: inline-block;
}

.hand {
  cursor: hand;
}

.pointer {
  cursor: pointer;
  outline: none;
}

.clear {
  clear: both;
}

//z-index
.z1 {
  z-index: 1;
}

//widths
.fill {
  width: 100%;
}

.wd-200 {
  width: 200px;
}

//heights
.ht-auto {
  max-height: none !important;
  height: auto !important;
}

//margins
.m0 {
  margin: 0;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-0 {
  margin-bottom: 0;
}



