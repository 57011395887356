md-card {
  &.md-default-theme {
    background-color: rgb(255, 255, 255) !important;
  }
  background-color: rgb(255, 255, 255) !important;

  &.active {
    background-color: $accent-200 !important;
  }

}

.card-filters {
  width: 100%;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
}

.available-facets {
  background-color: white;
}

.no-thumbnail {
  height: 20px;
}

/* quote styling
 ref https://css-tricks.com/snippets/css/simple-and-nice-blockquote-styling/ */

blockquote {
  background: $gray-light;
  border-left: 10px solid $gray;
  padding: 0.5em 10px;
  quotes: "“" "”" "“" "”";
  /*quotes: "\201C""\201D""\2018""\2019";*/
  &:before {
    color: $gray;
    content: open-quote;
    font-size: 2em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  p {
    display: inline;
  }
  &.highlighted {
    margin: 0px 0px 0px 5px;
  }
}

highlighted {
  font-weight: 500;
  background: rgba(238, 255, 65, 0.75);
  padding: 0 2px;
}

.missing-media {
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.54);
  color: rgba(250, 250, 250, 0.84);
  padding: 3px;
}

//grid-card

.grid-tile {
  float: left;
  width: 210px;
  display: inline-block;
  transition: width 0.6s cubic-bezier(0.58, 1.5, 0.22, 0.79);
  min-width: 210px;
  position: relative;
  .textThumb {
    min-height: 50px;
  }
  .play-overlay {
    opacity: .5;
    color: #fff;
    position: absolute;
    top: 25%;
    margin-top: -25px;
    left: 50%;
    margin-left: -25px;
    font-size: 50px;
  }
  .thumb {
    width: 194px;
    height: 110px;
  }
  .md-list-item-text {
    min-width: 0;
  }
  md-card {
    position: relative;
    overflow: hidden;
    align-items: flex-start;
    > md-list-item {
      width: 194px;
    }
  }
  .asset-timestamp {
    margin-bottom: 5px;
  }
  .md-body-2 {
    line-height: 1.2em;
    margin: 2px 0;
  }
  .metadata {
    position: absolute;
    left: 210px;
    top: 0;
    height: 100%;
    width: 370px;
    box-sizing: border-box;

    md-list-item {
      padding-right: 0;
    }

    .md-list-item-text {
      padding-right: 16px;
      max-height: 175px;
      overflow-y: auto;
    }

  }
  md-card-content {
    padding: 8px 18px 8px 8px;
    height: 120px;
  }

  .grid-card-text {
    width: 168px;
    display: inline-block;
    @include ellipsis();
  }

  .expand-metadata {
    position: absolute;
    right: 0;
    bottom: 6px;
    outline: none;
  }
  h4 {
    margin-top: 5px;
  }
  .sourcelogo {
    margin-top: 10px;
    img {
      height: 20px;
    }
  }
}

//grid-tile

.metadata md-list-item {
  min-height: 0;
  max-height: 38px;
}

.metadata md-list-item md-icon {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.expanded-left.grid-tile {
  md-card {
    align-items: flex-end;
  }
  .metadata {
    left: auto;
    right: 210px;
  }
}

/* Asset Type Flag */

div {
  &.grid-tile-wrapper {
    float: left;
    /* important */
    position: relative;
    /* important(so we can absolutely position the description div */
    width: 194px;
  }
}

//END grid-card

//list-card

.list-tile {
  h6, h5, .title-element {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .play-overlay {
    opacity: .5;
    color: #fff;
    position: absolute;
    top: 50%;
    margin-top: -25px;
    left: 50%;
    margin-left: -25px;
    font-size: 50px;
  }
  .md-list-item-text {
    min-width: 0;
  }
  .title-data {
    width: 300px;
  }
  .thumb {
    height: 110px;
    width: 194px;
    position: relative;
    overflow: hidden;
  }
  .textThumb {
    min-height: 50px;
  }
  h4 {
    margin: 0;
  }
  md-card-content {
    padding: 0;
  }
}

//END list-card

//simple-card
.simple-grid-tile {
  float: left;
  width: 210px;
  display: inline-block;
  min-width: 210px;
  height: 276px;
  position: relative;
  .thumb {
    height: 260px;
    width: 194px;
  }
  md-card {
    position: relative;
    overflow: hidden;
    align-items: flex-start;
  }
  md-card-content {
    padding: 8px 18px 8px 8px;
    height: 90px;
  }
  h4 {
    margin-top: 5px;
  }
}

.hidden-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  opacity: 0;
  padding: 5px 8px;
  transition: opacity 1s;
  font-size: 12px;
}

.simple-grid-tile:hover .hidden-header {
  opacity: 1;
}

//END simple-card


// card set landing text
md-card.landing-text {

  md-card-content md-card-title-text {

    padding-bottom: 10px;

    .ge-logo {
      font-size: 45px;
      margin-right: 10px;
    }
    
    .title {
      font-size: 28px;
    }

    .sub-title {
      font-size: 20px;
    }

  }

  .md-subhead md-icon {
    font-size: 18px;
    position: relative;
    top: -3px;
  }

  .card-set-boxes > md-card md-card-title {
    flex: none;
    md-icon {
      color: $bg-200;
    }
  }


}
