.panel-facet-search {
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  max-height: inherit;
  min-width: $facet-search-width-sm;

  @media (min-width: $breakpoint-tablet) {
    top: 10%;
    left: 310px;
    bottom: 10%;
    right: auto;
    min-width: $facet-search-width;
  }

}


.facet-search {
  position: relative;

  .options, .search-container, .sort-container {
    padding: 10px;
  }

  .options {
    background: #fff;
    padding-top: 0;
    padding-bottom: 0;
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
    bottom: 0;
    padding-right: 0;

    &.options-tall {
      top: 55px;
    }


    md-checkbox {
      min-width: $facet-search-width-sm - 65;

      @media (min-width: $breakpoint-tablet) {
        min-width: $facet-search-width - 65;
      }

      .md-label {
        width: auto;
      }

      .facet-option {
        width: $facet-search-width-sm - 85; // checkbox label text container

        @media (min-width: $breakpoint-tablet) {
          width: $facet-search-width - 85; // checkbox label text container
        }
      }

      .option-name {
        font-size: 12px;
      }

    }

  }

  .loading {
    background: rgba(255,255,255,0.15);
    padding-top: 0;
    padding-bottom: 0;
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
    bottom: 0;
    padding-right: 0;

    md-progress-circular {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -48px;
      margin-top: -48px;
    }

  }

  .options-container {
    position: relative;

    .virtual-repeat-wrapper, .vertical-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .repeated-item {
      box-sizing: border-box;
      height: 28px;
    }

    .group-heading {

      > div {
        width: 100%;
      }

      span {
        font-weight: bold;
        display: block;
        width: 85%;
        line-height: 18px;
        border-bottom: solid 1px $gray;
      }
    }

  }

  .search-container {
    padding-bottom: 0;
    height: 45px;


    .spacer {
      min-width: 50px;
    }

  }

  .sort-container {
    padding-top: 0;
    height: 45px;

    .sort-label {
      display: inline-block;
      height: 42px;
      line-height: 42px;
      padding: 2px;
      font-size: 12px;
      font-weight: bold;
    }

    .md-button {
      min-width: 30px;
      min-height: 30px;
      line-height: 30px;
      padding: 0;
      margin-left: 6px;
      margin-right: 6px;

      &:first-child {
        margin-left: 0;
      }

      &.active {
        background-color: $bg-50;
      }

    }

    md-select {
      margin: 0 0 0 15px;
    }

  }

  .letters-container {
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 50px;

    .md-button {
      margin: 0;
      min-width: 32px;
      min-height: 20px;
      line-height: 20px;
    }

  }

}
