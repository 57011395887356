
/* MEDIA QUERIES --------------------------------------------------*/

$breakpoint-mobile:           600px;
$breakpoint-tablet:           768px;
$breakpoint-desktop-sm:       960px;
$breakpoint-desktop:          1025px;
$breakpoint-desktop-lg:       1280px;
$breakpoint-desktop-xl:       1440px;

$screen-mobile:               "screen and (max-width: #{$breakpoint-mobile})";
$screen-tablet:               "screen and (min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-desktop - 1})";
$screen-tablet-lg:            "screen and (min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-desktop-lg - 1})";
$screen-desktop-sm:           "screen and (min-width: #{$breakpoint-desktop-sm})";
$screen-desktop:              "screen and (min-width: #{$breakpoint-desktop})";
$screen-desktop-lg:           "screen and (min-width: #{$breakpoint-desktop-lg})";
$screen-desktop-xl:           "screen and (min-width: #{$breakpoint-desktop-xl})";

$screen-upto-tablet:          "screen and (max-width: #{$breakpoint-tablet - 1})";
$screen-upto-desktop-sm:      "screen and (max-width: #{$breakpoint-desktop-sm - 1})";
$screen-upto-desktop:         "screen and (max-width: #{$breakpoint-desktop - 1})";
$screen-upto-desktop-lg:      "screen and (max-width: #{$breakpoint-desktop-lg - 1})";
$screen-upto-desktop-xl:      "screen and (max-width: #{$breakpoint-desktop-xl - 1})";

/* FONTS ---------------------------------------------------------*/

$font-path:                   "../fonts/";
$line-height-base:            16px;


/* COLORS -----------------------------------------------------------------------
defined in themecolors.json and injected with a sass loader custom function.
See https://itnext.io/sharing-variables-between-js-and-sass-using-webpack-sass-loader-713f51fa7fa0
-------------------------------------------------------------------------------*/

//Primary Palette (Indigo)
$primary-50:                  get("primary-50");
$primary-100:                 get("primary-100");
$primary-200:                 get("primary-200");
$primary-300:                 get("primary-300");
$primary-400:                 get("primary-400");
$primary-500:                 get("primary-500");
$primary-600:                 get("primary-600");
$primary-700:                 get("primary-700");
$primary-800:                 get("primary-800");
$primary-900:                 get("primary-900");
$primary-A100:                get("primary-A100");
$primary-A200:                get("primary-A200");
$primary-A400:                get("primary-A400");
$primary-A700:                get("primary-A700");

//Accent Palette (Lime)
$accent-50:                   get("accent-50");
$accent-200:                  get("accent-200");
$accent-A400:                 get("accent-A400");

//Background Palette (Blue Grey)
$bg-50:                       get("bg-50");
$bg-100:                      get("bg-100");
$bg-200:                      get("bg-200");
$bg-800:                      get("bg-800");
$bg-A100:                     get("bg-A100");
$bg-A200:                     get("bg-A200");
$bg-A400:                     get("bg-A400");
$bg-A700:                     get("bg-A700");

//Warning Palette (Amber)
$warning-100:                  get("warning-100");
$warning-500:                  get("warning-500");
$warning-900:                  get("warning-900");

//Danger Palette (Pink)
$danger-100:                  get("danger-100");
$danger-500:                  get("danger-500");
$danger-700:                  get("danger-700");
$danger-900:                  get("danger-900");


//shades of gray
$gray-light:                  #f9f9f9;
$gray:                        darken($gray-light, 17%); //#ccc
$gray-dark:                   darken($gray-light, 35%);
$gray-darker:                 darken($gray-light, 50%);
$gray-darker:                 darken($gray-light, 55%);


//alpha search panel
$facet-search-width:           365px;
$facet-search-width-sm:        300px;

//facet checkbox
$facet-checkbox-size:          16px;
$facet-checkbox-icon-size:     12px;

//z-indexes
$countdown-timer-zindex:       100000;
$playback-rate-zindex:         1000;
$md-toast-zindex:              90210;
$md-dialog-zindex:             1000;
$md-tooltip-zindex:            10000;
$footer-zindex:                100;


//table
$table-font-base:               13px;
$table-cell-padding:            8px;
$table-condensed-cell-padding:  5px;

$table-bg:                      transparent;
$table-bg-accent:               $gray-light;
$table-bg-hover:                #f5f5f5;
$table-bg-active:               $table-bg-hover;
$table-border-color:            #ddd ;
