html {
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: $footer-zindex;
}

.copyright-footer {
  font-size: 12px;
  min-height: 0;
  padding: 2px 0;
}