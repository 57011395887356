.copy-url-panel {
    
    background: white;
    border-radius: 4px;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
        0 13px 19px 2px rgba(0, 0, 0, 0.14),
        0 5px 24px 4px rgba(0, 0, 0, 0.12);
    min-width: 380px;

    .copy-url-panel-content {
        position: relative;
    }

    md-progress-circular {
        position: absolute;
        top: 50%;
        margin-top: -16px;
        left: 5px;
    }

    .md-button.md-fab.md-mini {
        display: none;
        position: absolute;
        right: -8px;
        top: -8px;
        margin: 0;
        padding: 0;
        min-height: 16px;
        height: 16px;
        width: 16px;
        line-height: 14px;
        md-icon {
            font-size: 14px;
        }
    }

    .form-control {
        font-size: 13px;
    }

    .input-group {

        .input-group-addon {
            border: none;
            height: 32px;
            min-width: 85px;
            font-size: 12px;
        }

    }

}