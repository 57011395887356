md-sidenav,
md-sidenav.md-locked-open,
md-sidenav.md-closed.md-locked-open-add-active {
  min-width: 300px !important;
  width: 300px !important;
  max-width: 300px !important;
}

md-sidenav {

  .search-bar {

    .md-title {
      margin: 0 0 5px 0;
      font-size: 14px;
      font-weight: 600;
      color: $gray-darker;
    }

  }


}