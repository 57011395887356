drm-player {

  .video-js {

    .vjs-playback-rate {
        z-index: $playback-rate-zindex;
    }

    // for the iframe cookie hack to make video appear as if it's loading durning iframe src request
    &.vjs-custom-loading {
      .vjs-big-play-button {
        display: none;
      }
      .vjs-loading-spinner {
        display: block;
        &:before, &:after {
          -webkit-animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
          animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
        }
      }
    }

    &.vjs-has-started {
      .close-video, .video-title {
        opacity: 1;
      }
      &.vjs-user-inactive.vjs-playing {
        .close-video, .video-title {
          opacity: 0;
        }
      }
    }

    .vjs-big-play-button {
      top: 50%;
      left: 50%;
      margin-left: -1.5em;
      margin-top: -0.75em;
    }

    .video-title {
      position: absolute;
      background: rgba(0,0,0,0.8);
      padding: 4px;
      border-radius: 4px;
      left: 5px;
      top: 5px;
      display: inline-block;
      max-width: 50%;
      @include ellipsis();
      font-size: 18px;
    }

    .close-video {
      position: absolute;
      background: rgba(0,0,0,0.8);
      height: 3em;
      width: 3em;
      border-radius: 100px;
      right: 5px;
      top: 5px;
      box-shadow: 0 5px 5px 0 rgba(0,0,0,.35);
      transition: opacity .1s;
      &:before {
        font-family: 'Material Icons';
        content: "\e5cd"; //close icon
        font-size: 18px;
        position: relative;
      }
    }

    .countdown-timer {
      font-size: 1em;
      color: white;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 0.5em;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      z-index: $countdown-timer-zindex;
    }

    .vjs-progress-control .vjs-progress-holder {
      font-size: 1.666em !important;
    }

    .vjs-play-progress {
      background-color: #fff;
      &:before {
        // https://videojs.github.io/font/
        content: "\f118"; // videojs circle-inner-circle icon
        background-color: rgba(0,0,0,0.35);
        border-radius: 100px;
      }
    }

    .vjs-control-bar{
      height: 5em;
      flex-wrap: wrap;
      background: rgba(0,0,0,0.8);
      overflow: hidden;

      &.segments {
        height: 6em;
        overflow: visible;

        .vjs-progress-control {
          height: 20%;
          margin-top: -2px;
          z-index: 5;
        }
      }

      .vjs-control:not(.vjs-progress-control) {
        height: 30px;
      }

      .vjs-progress-control {
        width: 100%;
        height: 35%;
      }

      .segment-container {
        font-size: 1.666em !important;
        height: 14px;
        width: 100%;
        margin: 0 .45em;
        position: relative;
        //background: greenyellow; //handy for debugging

        .segment-wrapper {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          white-space: nowrap;
          //background: red; //handy for debugging

          &:hover {
            z-index: 10;
            bottom: -15px;
            .segment {
              transform: scaleY(1);
              &.segment-highlight:after {
                display: none;
              }
            }
          }

          .segment {
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            height: 100%;
            background-color: $primary-500;
            box-shadow: inset 1px 1px 1px rgba(255, 255, 255, .65), inset -1px -1px 1px rgba(0, 0, 0, .35);
            opacity: 0.85;
            transition: background-color 250ms linear, transform 150ms ease-in-out;

            &.segment-empty {
              background-color: #000;
                &:hover {
                  cursor: default;
                  background-color: #000;
              }
            }

            &.segment-highlight {
              background-color: $accent-200;
              &:after {
                content:"";
                box-sizing: border-box;
                display: block;
                position: absolute;
                top: -7px;
                left: 50%;
                margin-left: -6px;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid #fff;
              }
              &:hover {
                background-color: $accent-200;
              }
            }


            &:hover {
              background-color: $primary-300;
              cursor: pointer;
              opacity: 1;
            }

            &.zoom {
              transform: scaleY(1.5);
              transform-origin: center center;
              box-shadow: inset 1px 0 0 rgba(255, 255, 255, 1), inset -1px 0 0 rgba(0, 0, 0, 0);
              &.center {
                transform: scaleY(2.5);
              }
            }

          }

        }

      }


      .copy-url {
        width: 100%;
        height: 40%;
        display: none;
        padding: 0 .45em .45em .45em;

        input {
          flex-grow: 1;
          background: #fff;
          box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
          color: #000;
          border:solid 1px $gray;
          border-radius: 4px 0 0 4px;
          padding: 3px 6px;
          height: 24px;
          font-size: 11px;
        }

        button {
          background-color: $gray-dark;
          border-radius: 0 4px 4px 0;
          cursor: pointer;
          height: 24px;
          width: 80px;
          font-size: 11px;
          &.copied {
            background-color: $primary-500;
          }
        }

      } //.copy-url

      &.show-copy-url {
        height: 10em;
        .vjs-control:not(.copy-url) {
          height: 20% !important;
        }
        .vjs-progress-control {
          margin-top: -6px;
        }
        .loop-segment-btn .vjs-control-text {
          margin-top: 0;
        }
        .copy-url {
          display: flex;
          align-items: center;
        }
      }

      .loop-bar {
        position: absolute;
        top: -2px;
        bottom: -2px;
        background-color: $danger-500;
        &:before, &:after {
          display: block;
          content: "";
          border: solid 2px #fff;
          position: absolute;
          top: -1px;
          bottom: -1px;
        }
        &:before {
          left: -2px;
          border-right: 1px solid transparent;
          box-shadow: -1px 0 3px 0 rgba(0,0,0,0.85);
        }
        &:after {
          right: -2px;
          border-left: 1px solid transparent;
          box-shadow: 1px 0 3px 0 rgba(0,0,0,0.85);
        }
      }

      .vjs-time-control, .vjs-time-divider{
        display: flex;
        font-size: 12px;
        line-height: 2.7em;
      }
      .vjs-time-divider {
        padding: 0;
        min-width: 0;
      }

      .spacer {
        flex-grow: 1;
        width: 3em;
      }

      .loop-segment-container {
        min-width: 100px;
      }

      //icon for restart segment
      .restart-loop:before {
        font-family: 'Material Icons';
        content: "\e042"; //replay icon
        font-size: 18px;
      }

      .metadata-view-spacer {
        display: flex;
        flex-grow: 1.25;
      }

      .metadata-view-button {
        display:flex;
        min-width: 140px;
        .vjs-control-text {
          clip: inherit;
          height: auto;
          position: absolute;
          width: auto;
          left: 30px;
          top: 50%;
          margin-top: -10px;
          font-size: 12px;
          @include ellipsis();
        }
        &:before {
          position: relative;
          font-family: 'Material Icons';
          content: "\e54d"; //local movies icon
          font-size: 18px;
          width: 18px;
          top: 4px;
          left: 6px;
        }
        &.opened:before, &.opened .vjs-control-text {
          color: $accent-200;
        }
        &:disabled {
          opacity: 1;
          &:before, .vjs-control-text {
            opacity: .5;
          }
        }
      }

      // icon for share button
      .share-link:before {
          font-family: 'Material Icons';
          content: "\e15e"; //reply icon
          font-size: 18px;
          transform: scaleX(-1); //flipped to point right
      }

      // loop segment "checkbox" (actually vjs button)
      .loop-segment-btn {
        display: flex;
        min-width: 100px;
        top: 5px;
        .vjs-control-text {
          clip: inherit;
          height: auto;
          position: absolute;
          width: auto;
          left: 24px;
          top: 50%;
          margin-top: -10px;
          font-size: 12px;
          @include ellipsis();
        }
        &:before {
          font-family: 'Material Icons';
          content: "\e835"; //check_box_outline_blank icon
          font-size: 18px;
          width: 18px;
        }
        &.checked:before {
          content: "\e834"; //check_box icon
          color: $accent-200;
        }
      }

    } // vjs-control-bar

    &.vjs-fullscreen {
      .vjs-control-bar {
        .metadata-view-spacer {
          flex-grow: 1.25;
        }
      }
    }

    &:not(.vjs-fullscreen) {
      .vjs-control-bar {
        // metadata view button appears below player when NOT in fullscreen mode, or if metadata viewer is opened
        .metadata-view-button:not(.opened) {
          background: rgba(0, 0, 0, 0.85);
          position: absolute;
          left: 50%;
          margin-left: -70px;
          bottom: -30px;
          height: 50% !important;
          line-height: 22px;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          box-shadow: 0 7px 8px -4px rgba(0,0,0,.2), 0 13px 19px 2px rgba(0,0,0,.14), 0 5px 24px 4px rgba(0,0,0,.12);
        }
      }
    }

  } // .video-js

} // drm-player

drm-player md-switch {
  margin: 0;
  padding: 0 2px;
  color: rgb(255, 255, 255);
  font-size: 0.8em;
  line-height: inherit;
  height: 25px;

  .md-container {
    height: 20px;
    width: 30px;
    margin-top: 1px;
  }

  .md-bar {
    height: 10px;
    width: 25px;
  }

  .md-thumb-container {
    width: 15px;
  }

  .md-thumb {
    width: 15px;
    height: 15px;
  }
}

segment-tooltip {
  position: fixed;
  z-index: $md-tooltip-zindex;
  top: 0;
  left: 0;
  color: #fff;
  max-width: 430px;
  font-size: 11px !important;
  display: none;

  &.left {
    .segment-tooltip-content {
      border-bottom-left-radius: 0;
      &:after, &:before {
        left: 10px;
      }
    }
  }
  &.right {
    .segment-tooltip-content {
      border-bottom-right-radius: 0;
      &:after, &:before {
        left: auto;
        right: 1px;
      }
    }
  }
  .segment-tooltip-content {
    position: relative;
    background: rgba(0,0,0,0.8);
    border: 1px solid #fff;
    border-radius: 4px;

    &:after, &:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: rgba(0, 0, 0, 0);
      border-top-color: #000;
      border-width: 10px;
      margin-left: -10px;
    }
    &:before {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #fff;
      border-width: 11px;
      margin-left: -11px;
    }

    md-list > md-list-item:last-child > md-divider {
      visibility: hidden;
    }

    .thumbnail {
      text-align: center;
      width: 170px;
      height: 100px;
      padding: 5px;
      box-sizing: border-box;

      img {
        width: 160px;
        height: 90px;
        &.error {
          opacity: 0.35;
        }
      }

    }

}



  md-list-item.md-3-line, md-list-item.md-3-line>.md-no-style {
    height: auto;
    min-height: 0;

    .md-list-item-text {
      font-size: 11px;
    }

    .md-avatar-icon {
      margin: auto;
    }

  }

  md-divider {
    border-top-color: rgba(255,255,255,0.12);
  }

}
