.utility-page {

  md-toolbar {
    background-color: $primary-500;
    color:rgba(255,255,255,.87);

    a {
      color:rgba(255,255,255,.87);
      text-decoration: none;
    }
  }

  .workspace {
    background-color: $gray-light;
  }

  .layout-padding {
    padding:18px;
  }

  md-card {     
    md-card-header {
      background-color: $primary-500;
      color: rgba(255,255,255,.87);
      text-align: right;
    }

    md-card-title, md-card-content {
      text-align: center;
      padding: 30px;
    }

    md-card-title {
      padding-bottom: 15px;
    }

    md-card-content {
      padding-top: 0;
    }
}

  .cover {
    margin-top: 10%;
  }

}